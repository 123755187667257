.choices__inner {
    height: calc(0.45em + 1.25rem + 5px);
    padding-bottom: 0;
    padding-top: 0;
}

.choices__list--dropdown {
    padding-bottom: 0;
    padding-top: 0;
}
