/*===============================
=            Choices            =
===============================*/

.choices {
  position: relative;
  margin-bottom: 24px;
  font-size: 16px;
}

.choices:focus {
  outline: none;
}

.choices:last-child {
  margin-bottom: 0;
}

.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #EAEAEA;
  cursor: not-allowed;
  user-select: none;
}

.choices.is-disabled .choices__item {
  cursor: not-allowed;
}

.choices[data-type*="select-one"] {
  cursor: pointer;
}

.choices[data-type*="select-one"] .choices__inner {
  height: calc(1.5em + 1.25rem + 5px);
}

.choices[data-type*="select-one"] .choices__input {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #DDDDDD;
  background-color: $white;
  margin: 0;
}

.choices[data-type*="select-one"] .choices__button {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  padding: 0;
  background-size: 8px;
  position: absolute;
  right: 0;
  margin-right: 25px;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: .5;
}

.choices[data-type*="select-one"] .choices__button:hover, .choices[data-type*="select-one"] .choices__button:focus {
  opacity: 1;
}

.choices[data-type*="select-one"] .choices__button:focus {
  box-shadow: 0px 0px 0px 2px #00BCD4;
}

.choices[data-type*="select-one"]:after,
.choices[data-type*="select-multiple"]:after {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
  transition: all .3s ease;
}

.choices[data-type*="select-one"].is-open:after,
.choices[data-type*="select-multiple"].is-open:after {
  transform: rotate(180deg);
  margin-top: -7.5px;
}

.choices[data-type*="select-one"][dir="rtl"]:after {
  left: 11.5px;
  right: auto;
}

.choices[data-type*="select-one"][dir="rtl"] .choices__button {
  right: auto;
  left: 0;
  margin-left: 25px;
  margin-right: 0;
}

.choices[data-type*="select-multiple"] .choices__inner,
.choices[data-type*="text"] .choices__inner {
  cursor: text;
}

.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  position: relative;
  display: inline-block;
  margin: 0 -15px 0 30px;
  padding-right: 0;
  width: 8px;
  line-height: 1;
  opacity: .75;
  border-radius: 0;

  &:after {
    font-family: 'Font Awesome 5 Free';
    content: "\f00d";
    display: inline-block;
    position: absolute;
    right: 19px;
    top: 2px;
    font-size: 11px;
    color: $white;
    text-indent: 1px;
    font-weight: 600;
  }
}

.choices[data-type*="select-multiple"] .choices__button:hover, .choices[data-type*="select-multiple"] .choices__button:focus,
.choices[data-type*="text"] .choices__button:hover,
.choices[data-type*="text"] .choices__button:focus {
  opacity: 1;
}

.choices__inner {
  font-size: 0.875rem;
  height: calc(1.5em + 1.25rem + 2px);
  padding: .32rem .75rem;
  line-height: 1.5;
  display: block;
  width: 100%;
  transition: all .15s cubic-bezier(.68,-.55,.265,1.55);
  color: #8898aa;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  background-color: $white;
  background-clip: padding-box;
  box-shadow: 0 3px 2px rgba(233,236,239,.05);
}


.is-open .choices__inner {
  border-radius: 2.5px 2.5px 0 0;
  border-color: #6270dd !important;
}

.is-flipped.is-open .choices__inner {
  border-radius: 0 0 2.5px 2.5px;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
  z-index: 2;

  .choices__input--cloned {
    padding: .5rem .75rem;

    &:focus {
      color: #8898aa;
      border-color: #5e72e4;
      outline: 0;
      background-color: $white;
      box-shadow: 0 3px 9px rgba(50,50,9,0),
                  3px 4px 8px rgba(94,114,228,.1);
    }
  }
}

.choices__list--single {
  display: inline-block;
  width: 100%;
}

[dir="rtl"] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px;
}

.choices__list--single {
  margin-top: calc(1em / 2);
}

.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}

.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: 20px;
  padding: 2px 10px;
  font-size: 0.875rem;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 0;
  color: #00BCD4;
  word-break: break-all;
}

.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}

[dir="rtl"] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px;
}


.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaa;
  border: 1px solid #919191;
}

.choices__list--dropdown {
  display: none;
  padding: .5rem 0;
  border-radius: .3rem;
  background-color: $white;
  word-break: break-all;
}

.choices__list--dropdown.is-active {
  position: absolute;
  width: 100%;
  display: block;
  margin-top: 10px !important;
  z-index: 999;
  bottom: unset !important;
  top: 100% !important;
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
          animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transform: none !important;
  transition: $transition-collapse;
  border: 1px solid #dee2e6;
  border-radius: 0.4375rem !important;

  &:before {
    display: none;
    background: $white;
    box-shadow: none;
    content: "";
    height: 16px;
    width: 16px;
    right: 15px;
    position: absolute;
    bottom: 100%;
    transform: rotate(-45deg) translateY(1rem);
    z-index: -5;
    border-radius: .2rem;
  }
}

.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: .25rem .25rem 0 0;
}

.choices__list--dropdown .choices__list {
  position: relative;
  max-height: 300px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}

.choices__list--dropdown .choices__item {
  padding: 1rem;
  font-size: $font-size-sm;
}

[dir="rtl"] .choices__list--dropdown .choices__item {
  text-align: right;
}

@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable:after {
    font-size: 12px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  [dir="rtl"] .choices__list--dropdown .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
  }
  [dir="rtl"] .choices__list--dropdown .choices__item--selectable:after {
    right: auto;
    left: 10px;
  }
}

// .choices__list--dropdown .choices__item--selectable.is-highlighted {
//   -webkit-transform: scale(1.1);
//   -ms-transform: scale(1.1);
//   transform: scale(1.1);
//   border-radius: .3rem;
//   box-shadow: 0 7px 14px rgba(50,50,93,.1),
//               0 3px 6px rgba(0,0,0,.08);
//   background: $white;
// }

.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
  opacity: .5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: .5;
}

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #f7f7f7;
  color: gray;
}

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.choices__button:focus {
  outline: none;
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  font-size: 14px;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
}

.choices__input:focus {
  outline: 0;
}

[dir="rtl"] .choices__input {
  padding-right: 2px;
  padding-left: 0;
}

.choices__placeholder {
  opacity: .5;
}

.choices__input.is-hidden,
.choices[data-type*="select-one"] .choices__input.is-hidden,
.choices[data-type*="select-multiple"] .choices__input.is-hidden {
  display: none;
}

/*=====  End of Choices  ======*/


// CT Style

.choices {
  &[data-type*="select-multiple"] {
    .choices__inner {
      display: table;
      border-radius: 4px;
    }
  }
}

.choices[data-type*="text"] {
  .choices__inner {
    border: none;
  }

  .choices__item {
    line-height: 1.5;
    position: relative;
    margin: .125rem;
    padding: .625rem .625rem .5rem;
    transition: all .30s ease;
    color: $white;
    border-radius: .25rem;
    background: #172b4d;
    box-shadow: 0 1px 2px rgba(68,68,68,.25);
  }

  .choices__list--dropdown {
    display: none !important;
  }
}

[data-type="select-one"] {
  .choices__item.choices__item--selectable {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 2em;
    color: #8898aa !important;
  }
}

[data-type="select-multiple"] {
  .choices__list--multiple .choices__item.is-highlighted {
    background-color: transparent !important;
    border: none !important;
    color: $primary !important;
  }
  .choices__item.choices__item--selectable {
    color: #8898aa !important;
  }
  .choices__button:after {
    color: $default !important;
  }
}

@include media-breakpoint-down(xs) {
  .dropdown.show .dropdown-menu.show .dropdown-item.dropdown-toggle.open+.dropdown-menu.show {
      left: 30% !important;
      margin-top: 33%!important;
  }
  .dropdown.show .dropdown-menu.show .dropdown-menu.show .dropdown-menu:first-of-type.show {
    top: 75%!important;
  }
}
