.date-select-group {
    margin: 0 1em 0 1em;
    display: flex;
    flex-direction: row;
}

.date-select-group > .form-group > label {
    margin: 0 0 0.5em 0;
    font-size: 0.7em;
}

.date-select > input {
    max-height: 2em;
    max-width: 12em;
}

.invalid-border > input {
    border: 1px solid #fb6340;
}
